/* .dashboard-parent-flex{
    display: flex;
   
} */

.dashboard-box1{
width: 255px;
height: 113px;
border: 1.5px solid #D9D9D9;
padding: 20px;
}

.dashboard-box1 h1{
font-family: 'Inter';
font-style: normal;
font-weight: 500;
color: #6C6565;
font-size: 14px
}


/* .dashboard-box1 h2{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 28px;
margin-top: 10px;
} */

.dashboard-box2{
    width: 255px;
height: 113px;
border: 1.5px solid #D9D9D9;
}

.dashboard-box3{
    width: 255px;
height: 113px;
border: 1.5px solid #D9D9D9;
}

.dashboard-box4{
    width: 255px;
height: 113px;
border: 1.5px solid #D9D9D9;
}

.dashboard-comp{
    margin: 3rem 7rem;
}

.inside-box1-flex{
    display: flex;
}

.inside-box1-flex p{
margin: 17px;
color: #E14343;
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 15px;
}

.inside-box1-flex h2{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 22px;
color: #000000;
margin-top: 9px;
}

.image-box-flex{
    display: flex;
    margin-top: 4rem;
}

.content-box{
    width: 75%;
    height: 202px;
    display: flex;
    border-radius: 10px;
    justify-content: space-between;
    border: 1.5px solid #D9D9D9;
}

.language-flex{
    display: flex;
}

.language-flex h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}


.budget-flex{
    display: flex;
    margin-top: 2rem;
}

.budget-flex h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}

.button-flex{
    display: flex;
    margin-top: 2rem;
}

.language-dailybudget{
    padding: 2rem;
}

.language-flex input{
    margin-left: 3rem;
    height: 30px;
    border-radius: 10px;
    border: solid 2px #D9D9D9;
}

.budget-flex input{
    margin-left: 27px;
  
    height: 30px;
    border-radius: 10px;
    border: solid 2px #D9D9D9;
}

.button-flex a{
    text-decoration: none;
    color: #C13B3A;
    font-family: 'Inter';
font-style: italic;
font-weight: 400;
font-size: 13px;
}

.button-flex p{
    color: #9A9797;
    font-family: 'Inter';
font-style: italic;
font-weight: 400;
font-size: 11px;
margin-left: 10px;
}

.second-box1-parent-flex{
    display: flex;
}

.second-box2-parent-flex{
    display: flex;
}

.second-inside-box1{
    width: 160px;
    height: 100px;
    text-align: center;
    padding-top: 24px;
    border: 1.5px solid #D9D9D9;
}

.second-inside-box1 h1{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
}

.second-inside-box1 p{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 13px;
}

.second-inside-box2{
    width: 160px;
    height: 100px;
    text-align: center;
    padding-top: 24px;
    border: 1.5px solid #D9D9D9;
}

.second-inside-box2 h1{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
}

.second-inside-box2 p{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 13px;
}

.second-inside-box3{
    width: 160px;
    height: 100px;
    text-align: center;
    padding-top: 24px;
    border: 1.5px solid #D9D9D9;
}

.second-inside-box3 h1{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
}

.second-inside-box3 p{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 13px;
}

.second-inside-box4{
    width: 160px;
    height: 100px;
    text-align: center;
    padding-top: 24px;
    border: 1.5px solid #D9D9D9;
}

.second-inside-box4 h1{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
}

.second-inside-box4 p{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 13px;
}

.second-image-list-flex{
    margin-left: 20px;
    border: 1.5px solid #D9D9D9;
    border-radius: 10px;
}

.image-heading-list h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #6C6565;
}

.image-heading-list{
    padding: 15px 30px;
}

.image-heading-list li{
    list-style: none;
    margin-top: 20px;
}

.grid-box1{
    border: 1.5px solid #D9D9D9;
}

.last-heading-para p{
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    color: #8A8080;
    margin-top: 30px;
}

.last-heading-para h5{
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    margin-top: 20px;
    color: #8A8080;
}

.date-inputbox input{
    height: 35px;
    border: solid 1.5px #D9D9D9;
}

.date-inputbox1 input{
    height: 35px;
    border-radius: 8px;
  border: solid 2px #979797;
}

.date-inputbox1 input::placeholder{
      font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
    padding-left: 10px;
}

.grid-box2{
    border: 1.5px solid #D9D9D9;
}

.last-img{
    padding: 25px 10px;
}

.last-img p{
    text-align: center;
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
    color: #EA7A7A;
}

.last-grp-img{
    text-align: end;
}

.last-grp-img img{
    margin: 10px;
}

.last-btn{
    text-align: end;
}

.last-btn button{
    margin: 10px;
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 12px;
background: #EA7A7A;
border-radius: 5px;
color: white;
border: none;
padding: 10px 15px;
}