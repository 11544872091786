.main-navbar{
    display: flex;
    justify-content: space-between;
}

.navbar-comp{
    margin: 0px 30px;
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    margin-bottom: 20px;
}

.parent-flex{
    display: flex;
}

.main-navbar a{
    text-decoration: none;
    margin: 20px;
    color:#000000;
}

.main-navbar li{
    list-style: none;
    margin-top: 7px;
}

.main-navbar img{
    height: 30px;
}

.main-navbar span{
    margin-top: 10px;
    font-size: 15px;
    color: #808588;
}

.parent-flex input{
    height: 24px;
}

.parent-flex img{
    height: 1.5rem;
}

.parent-flex p{
    margin-top: 5px;
}

.acount-img{
    margin-left: 15px;
}

.background-class{
    background: linear-gradient(180deg, #F3F3F3 0%, rgba(255, 255, 255, 0) 54.86%);
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15));
    height: 75px;
    background-color: #FFFFFF;
}





/* .lists-a{
    display: flex;
    margin-top: 25px;
} */

/* .lists-a a{
    text-decoration: none;
    margin: 10px;
}

.lists-a li{
    list-style: none;
} */